const map = {
    pulpe: 'La_Pulpe_2',
    smile: 'Smile',
    emerson: 'Emerson',
}

function getStorePrice(product, store) {
    const price = product[`Precio__${map[store]}_`] || product.Price // From graphql server comes as "Price"

    return product.price === 'variable' ? '0' : price
}

module.exports = getStorePrice
