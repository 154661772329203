import React from 'react'
import styled from 'styled-components'
import Link from 'gatsby-link'
import copy from 'copy-to-clipboard'

import AddButton from './AddButton'
import Price from './Price'
import Pricing from './styled/Pricing'
import ProductItemWrapper from './styled/ProductItemWrapper'
import ProductContent from './styled/ProductContent'
import ProductImage from './ProductImage'
import getProductData from '../utils/getProductData'

import extraInfo from '../data/extra'
import FreeShippingBadge from './FreeShippingBadge'

import StoreContext from '../context/StoreContext'

const ProductQuantity = styled.span`
    background-color: #fff;
    border-radius: 0.25rem;
    border: solid 2px ${props => props.theme.gray};
    font-size: 0.7rem;
    padding: 0 0.25rem;
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
`

const CustomLink = ({ children, url, title, store }) => {
    return store === 'pulpe' ? (
        <Link to={url} title={title}>
            {children}
        </Link>
    ) : (
        children
    )
}

export const ProductListItem = ({ className, product }) => {
    return (
        <StoreContext.Consumer>
            {({ store }) => {
                const productData = getProductData(product, store)
                const {
                    price,
                    url,
                    Nombre,
                    productQuantity,
                    REF,
                    FullName,
                    Categoria,
                } = productData
                const { freeShipping } = extraInfo[REF] || {}

                const linkTitle = `Ver el producto: ${Nombre}`

                return (
                    <ProductItemWrapper
                        onDoubleClick={() => {
                            copy(FullName)
                        }}
                        className={className}
                    >
                        {freeShipping && (
                            <FreeShippingBadge
                                style={{
                                    position: 'absolute',
                                    right: '-0.25rem',
                                    top: '0.5rem',
                                    transform: 'rotate(15deg)',
                                    zIndex: '1',
                                }}
                            />
                        )}
                        <CustomLink store={store} url={url} title={linkTitle}>
                            <ProductImage
                                size={119}
                                category={Categoria}
                                name={FullName}
                            />
                        </CustomLink>
                        {productQuantity && (
                            <ProductQuantity>{productQuantity}</ProductQuantity>
                        )}
                        <ProductContent>
                            <h4>
                                <CustomLink
                                    store={store}
                                    url={url}
                                    title={linkTitle}
                                >
                                    {Nombre}
                                </CustomLink>
                            </h4>
                            <Pricing>
                                <Price>{price}</Price>
                                <AddButton
                                    data={productData}
                                    track={store === 'pulpe'}
                                />
                            </Pricing>
                        </ProductContent>
                    </ProductItemWrapper>
                )
            }}
        </StoreContext.Consumer>
    )
}

export default ProductListItem
