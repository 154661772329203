import React from 'react'
import StoreContext from '../context/StoreContext'
import StoreActionButton from './styled/StoreActionButton'

import { CLOSE_MESSAGE } from '../utils/isClose'

const AddButton = ({ data, track }) => {
    return (
        <StoreContext.Consumer>
            {({ addItem, isClose }) => {
                return (
                    <StoreActionButton
                        onClick={() => {
                            if (isClose) {
                                alert(CLOSE_MESSAGE)
                            } else {
                                addItem(data, track)
                            }
                        }}
                    >
                        Agregar
                    </StoreActionButton>
                )
            }}
        </StoreContext.Consumer>
    )
}

export default AddButton
