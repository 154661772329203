import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import StoreLayout from '../../components/StoreLayout'

import HomeCategoriesShowcase from '../../components/HomeCategoriesShowcase'
import Helmet from 'react-helmet'

const Main = styled.main`
    margin: 1rem 0;
`

const MarginWraper = styled.div`
    margin: 0 var(--spacing-global);

    h1 {
        margin-bottom: 0.5rem;
    }
`

const IndexPage = ({ data, location }) => {
    const categories = data.allProductsCsv.group

    return (
        <StoreLayout
            ContentComponent={Main}
            location={location}
            seotitle="Productos Ticos"
            seourl={location.pathname}
        >
            <Helmet>
                <style type="text/css">{`
                    body {
                        background: url("${data.file.childImageSharp.fluid.src}") no-repeat center top / contain
                    }
                `}</style>
            </Helmet>

            <MarginWraper>
                <h1>Productos Ticos</h1>
                <h3>
                    En La Pulpe apoyamos a los productores ticos porque creemos
                    en ellos.
                </h3>
            </MarginWraper>

            <HomeCategoriesShowcase categories={categories} />
        </StoreLayout>
    )
}

export const query = graphql`
    query {
        file(relativePath: { eq: "images/productores-ticos.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1600) {
                    src
                }
            }
        }

        allProductsCsv(
            filter: {
                Nombre: {
                    in: [
                        "Alfajores Selva Negra"
                        "Ceres Queque Varios"
                        "Grace Queque Chocolate"
                        "Grace Queque Chocomani"
                        "Grace Queque Tres Leches"
                        "Grace Queques Zanahoria"
                        "Move Cheesecake Fresa"
                        "Move Cheesecake Maracuyá"
                        "Move Tres Leches"
                        "Palomitas Saladas Poppin"
                        "Pimiento Rosa Arroz Con Pollo"
                        "Pimiento Rosa Burrito De Carne"
                        "Pimiento Rosa Burrito De Pollo"
                        "Pimiento Rosa Casado Carne Y Pollo"
                        "Pimiento Rosa Ensaladas"
                        "Pimiento Rosa Keto Taco"
                        "Pimiento Rosa Lasaña De Pollo"
                        "Pimiento Rosa Pastas Varias"
                        "Poppin Palomitas de Maíz Caramelo"
                        "Poppin Palomitas de Maíz Dulce Salado"
                        "Poppin Palomitas de Queso Cheddar"
                        "Yolobon Cafe Organico"
                        "Yolobon Chocolate Organico"
                        "Yolobon Crema Limon"
                        "Yolobon Fresa Organica"
                        "Yolobon Mango Maracuya"
                        "Yolobon Mucho Coco"
                    ]
                }
            }
        ) {
            group(field: Proveedor) {
                fieldValue
                nodes {
                    ...ProductData
                }
            }
        }
    }
`

export default IndexPage
