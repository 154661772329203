import React from 'react'
import styled from 'styled-components'
import Link from 'gatsby-link'

import ProductListItem from './ProductListItem'

import sluglify from '../utils/sluglify'
import getStorePrefix from '../utils/getStorePrefix'

import StoreContext from '../context/StoreContext'

const Wrapper = styled.div`
    margin: 2rem 0;

    header {
        display: flex;
        justify-content: space-between;
        margin-right: var(--spacing-global);
        align-items: center;
        margin: 0 var(--spacing-global) 1rem;

        h2 {
            font-size: 1.6rem;
            margin: 0;
        }

        a {
            border: solid 1px ${props => props.theme.gray};
            color: ${props => props.theme.brandColor1Dark};
            text-decoration: none;
            padding: 0.1rem 1rem;
            border-radius: 1rem;
        }
    }
`

const ProductListCarousel = styled.div`
    --item-margin: 0.5rem;
    align-items: stretch;
    display: flex;
    overflow-x: scroll;
    padding: 0.25rem 1rem 1rem calc(var(--spacing-global) - var(--item-margin));
    scroll-padding: 0 50%;
    scroll-snap-type: x mandatory;
    scroll-margin: 1rem;

    .item {
        flex-shrink: 0;
        margin: 0 var(--item-margin);
        scroll-snap-align: center;
        width: var(--product-box-size);
    }
`

const CategoryProductList = ({ name, products, showMoreLink = true }) => {
    return (
        <Wrapper>
            <header>
                <h2>{name}</h2>
                {showMoreLink && (
                    <StoreContext.Consumer>
                        {({ store }) => {
                            const prefix = getStorePrefix(store)
                            return (
                                <Link
                                    title={`Ver todos los productos de la categoría ${name}`}
                                    to={`${prefix}/${sluglify(name)}`}
                                >
                                    Ver todos
                                </Link>
                            )
                        }}
                    </StoreContext.Consumer>
                )}
            </header>
            <ProductListCarousel>
                {products.map((product, k) => (
                    <ProductListItem
                        key={k}
                        className="item"
                        product={product}
                    />
                ))}
            </ProductListCarousel>
        </Wrapper>
    )
}

export default CategoryProductList
