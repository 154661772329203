import styled from 'styled-components'
import Card from '../Card'

const ProductItemWrapper = styled(Card)`
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    position: relative;

    h3,
    h4 {
        font-size: 0.9rem;
        margin-top: 0.5rem;
        margin-bottom: 0.25rem;
    }

    &.special {
        border: solid 2px ${props => props.theme.gray};
    }
`

export default ProductItemWrapper
