export default {
    'combo-cesta-basica': {
        contain: [
            '1 x Arroz Tio Pelon 91% 1,8Kg',
            '1 x Frijoles Don Pedro 900g (R&N)',
            '2 x Espaguetti Roma 250g',
            '1 x Azucar Doña Maria 1kg',
            '1 x Aceite Capullo 900ml',
            '2 x Harina Pan 500g',
            '1 x Cafe REY 500g',
            '1 x Sal Refinada Refisal 500g',
            '1 x Leche Semidescremada Dos Pinos 1L',
            '2 x Atun en Aceite Member Selection 170g',
        ],
        freeShipping: true,
    },
    'combo-desayuno': {
        contain: [
            '1 x Cereal Kelloggs Corn Flakes 400g',
            '1 x Pan de Sandwich 0% grasa Bimbo 500g',
            '6 x Huevos',
            '1 x Jugo de Naranja Sunfrut 1L',
            '1 x Leche Semidescremada Dos Pinos 1L',
            '1 x Cafe REY 250g',
            '1 x Avena Mosh 400gr',
            '1 x Tortilla Tortiricas 25uni',
            '1 x Natilla La Granja 300ML',
            '1 x Arroz Don Pedro 99% 800g',
            '1 x Frijoles Don Pedro 900g (R&N)',
            '1 x Salsa Lizano 135ml',
            '1 x Cocoa dulce 100gr',
            '1 x Salchichon criollo Zurqui 500g'
        ],
        freeShipping: true,
    },
}
