import React from 'react'
import styled from 'styled-components'

const FreeShippingWrapper = styled.div`
    background-color: ${props => props.theme.brandColor1};
    border-radius: 0.15rem;
    color: ${props => props.theme.brandColor1Dark};
    font-size: 0.65rem;
    font-weight: bold;
    padding: 0.15rem 0.25rem;
    text-transform: uppercase;
`

const FreeShippingBadge = (props) => (
    <FreeShippingWrapper {...props}>Envío Gratis</FreeShippingWrapper>
)

export default FreeShippingBadge
