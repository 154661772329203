import React from 'react'
import CategoryProductList from './CategoryProductList'

const HomeCategoriesShowcase = ({ categories, scrollPosition }) => {
    return categories.map(({ fieldValue, nodes }, i) => {
        return (
            <CategoryProductList key={i} name={fieldValue} products={nodes} />
        )
    })
}

export default HomeCategoriesShowcase
