import styled from 'styled-components'

const ProductContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;

    a {
        text-decoration: none;
        color: ${props => props.theme.black}
    }
`

export default ProductContent
