import styled from 'styled-components'

const StoreActionButton = styled.button`
    background-color: ${props => props.theme.brandColor2};
    border-radius: 1rem;
    border: 0;
    color: ${props => props.theme.brandColor2Dark};
    cursor: pointer;
    margin-top: 0.5rem;
    padding: 0.15rem 1rem;
    transition: transform 100ms ease-in-out;

    &:hover {
        transform: scale(1.1);
    }

    &.secondary {
        background-color: ${props => props.theme.brandColor1};
        color: ${props => props.theme.brandColor1Dark};
    }
`

export default StoreActionButton
