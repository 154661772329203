const getStorePrice = require('./getStorePrice')
const sluglify = require('./sluglify')

const productAmountRegex = /\d+(\.\d|,\d{1,2})?\s?(?!Up|Grasa)(kilos|kilo|ml|gm|kg|gr|g|k|unid|uni|und|u|L|pz)/gi

const getProductData = (product, store = 'pulpe') => {
    const price = getStorePrice(product, store)

    const currency = 'CRC'

    const url = `/${sluglify(product.Categoria)}/${sluglify(product.Nombre)}`
    const match = product.Nombre.match(productAmountRegex)
    const productQuantity = match
        ? match[0].toUpperCase().replace(/\s/, '')
        : null

    const Nombre = product.Nombre.replace(productAmountRegex, '').trim()

    return {
        Codigo_de_barras: product.Codigo_de_barras,
        Categoria: product.Categoria,
        REF: product.REF,
        currency,
        Nombre,
        FullName: product.Nombre,
        price,
        productQuantity,
        url,
        tracking: {
            content_name: Nombre,
            content_category: product.Categoria,
            content_ids: [product.REF],
            content_type: 'product',
            value: price,
            currency,
        },
    }
}

export default getProductData
